import { useRouteLoaderData } from '@remix-run/react';

import type { Locale } from '~/modules/language/@types/Locales';
import type { SubscriptionProvider } from '~/store/AuthStore';

import type { HttpUrl } from './@types/HttpUrl';

interface RootConfig {
  CLIENT_URL: HttpUrl;
}

export type RootData = {
  config: RootConfig;
  locale: Locale;
  isIOS: boolean;
  isAndroidOS: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isPremium: boolean;
  firebaseUid: string | null;
  subscriptionProvider: SubscriptionProvider | null;
};

export default function useRootData(): RootData {
  return useRouteLoaderData<RootData>('root') as RootData;
}

export const useRootConfig = (): RootData['config'] => {
  return useRootData().config;
};

export const useClientUrl = (): HttpUrl => {
  return useRootConfig().CLIENT_URL;
};
